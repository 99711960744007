import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const APILink = (props: any) => {
    let name = props.name
    let apiLink = props.apiLinkData

    function handleClick() {
        props.updateDefinitionLink(apiLink)
    }

    return (
        <ListItem button onClick={() => handleClick()}>
            <ListItemText primary={name}/>
        </ListItem>
    )
}

export default APILink;

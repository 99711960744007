import { InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import APILink from './APILink'

const Sidebar = (props: any) => {
    let organizationConfig = props.organizationConfig
    let dropDownElements = []
    const [selectedValue, setDropdown] = React.useState<string[]>([ "1"]);

    const handleChange = (event: any, child: any) => {
        setDropdown(event.target.value);
        props.updateDefinitionLink(event.target.value)
    };

    if (props.definitionList.length === 0) {
        props.getOrganizationData(organizationConfig.orgName)
    } else {
        for (let i = 0; i < props.definitionList.length; i++) {
            dropDownElements.push(
                <MenuItem value={props.definitionList[i].id}>{props.definitionList[i].name}</MenuItem>
            )
        }
    }
    return (
        <div className="side-bar">
            <div className="side-bar-body">
                <InputLabel id="service-label">API Services</InputLabel>
                <Select
                    labelId="service-label"
                    id="api-service"
                    label="Services"
                    value={selectedValue}
                    onChange={handleChange}>
                    {dropDownElements}
                </Select>
                <div className="side-bar-support">
                    <APILink
                        key={"Support"}
                        name={"Support Service"}
                        apiLinkData={-1}
                        updateDefinitionLink={props.updateDefinitionLink}
                    />
                </div>
            </div>
        </div>

    )
}

export default Sidebar;



import React, { Component } from 'react';
import SwaggerUI from "swagger-ui";
import Sidebar from './components/Sidebar';
import { SwaggerUIStandalonePreset } from "swagger-ui-dist"
const Config = require("./organization_config.json");

interface IProps {
    organizationConfig: string
    definitionList: any[] | undefined,
    definitionLink: string,
    schemaId: string
}

interface IState {
    organizationConfig: string
    definitionList: any[],
    definitionLink: string,
    schemaId: string
}

type Url = {
    name: string;
    url: string;
};

class App extends Component<IProps, IState> {
    static displayName = App.name;
    private baseurl: string = window.location.protocol + '//' + window.location.host

    constructor(props: IProps) {
        super(props);
        this.state = {
            organizationConfig: "",
            definitionList: [],
            definitionLink: this.baseurl + "/api/v1/schema?id=1",
            schemaId: "1"
        }

        this.getData = this.getData.bind(this)
        this.getOrganizationData = this.getOrganizationData.bind(this)
        this.updateDefinitionLink = this.updateDefinitionLink.bind(this)
    }

    updateDefinitionLink(id: number): void {
        if (id >= 0) {
            this.setState({
                definitionLink: this.baseurl + "/api/v1/schema?id=" + id,
                schemaId: id.toString()
            })
        } else {
            this.setState({
                definitionLink: this.baseurl + "/api/v1/schema/support",
                schemaId: ""
            })
        }
    }

    async componentDidUpdate(prevProps: IProps, prevState: IState) {
        let apiUrl = ""
        if (this.state.schemaId === "") {

            SwaggerUI({
                domNode: document.getElementById("api-data"),
                urls: [{url: "/api/v1/schema/support", name: "v2"}],
                plugins: [SwaggerUIStandalonePreset],
                layout: "StandaloneLayout",
                validatorUrl: "",
            })
        } else {
            apiUrl = "/api/v1/version/" + this.state.schemaId;

            const response = await fetch(apiUrl);

            if (!response.ok)  throw new Error('There was an issue requesting the API');

            if (response.ok) {
                const json = await response.json();
                this.bindUrl(json);
            }
        }
        document.getElementsByClassName('topbar-wrapper')[0].getElementsByTagName('img')[0].src = "Logo.png";
    }

    async getData(inputMethod: string, inputResource: string, inputParams: string) {

        const response = await fetch(inputResource, {
            method: inputMethod
        });

        if (response.ok) {
            return response.json();
        }
        throw new Error('There was an issue requesting the API');
    }

    bindUrl(data: Url[]) {

        for (const k in data) {
            const v = data[k];
            v.url = this.baseurl + v.url
        }

        SwaggerUI({
            domNode: document.getElementById("api-data"),
            urls: data,
            plugins: [SwaggerUIStandalonePreset],
            layout: "StandaloneLayout",
            validatorUrl: "",
        })
    }

    getOrganizationData(organization: string) {

        let inputParams = "page=0&limit=20&sort=NAME&order=ASC"

        this.getData('GET', 'api/v1/menu', inputParams).then(response => {
            this.setState({
                definitionList: response
            })
        })
    }

    componentWillMount() {
        this.setState({
            organizationConfig: Config.orgData,
        })
    }

    render() {
        return (
            <div className="App">
                <Sidebar
                    organizationConfig={this.state.organizationConfig}
                    definitionList={this.state.definitionList}
                    updateDefinitionLink={this.updateDefinitionLink}
                    getOrganizationData={this.getOrganizationData}
                />
                <div id="api-data" />
            </div>
        );
    }

}

export default App;